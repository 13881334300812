//变量
@c-333: #333;
@c-666: #666;
@c-999: #999;
@c-fff: #ffffff;
@c-2ff: #3072FF;
@c-202: #D00202;

.pay {
  min-height: 100vh;
  background: url('~@/assets/images/common/payBg.png') no-repeat center;
  background-size: cover;
  .wrap {
    padding-top: 33.33vw;
  }
  .container {
    width: 90vw;
    margin:0 auto;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 1.2vw 1.73vw 0px rgba(80, 156, 255, 0.2);
    border-radius: 2.67vw;
    padding: 20vw 2vw;
  }
  .loginBg {
    display: block;
    width: 13.33vw;
    margin: 0 auto;
  }
}

@blue: @c-2ff;